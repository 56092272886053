<template>
    <div class="not-found">
      <h1>404 - Page Not Found</h1>
      <p>The page you are looking for doesn't exist or has been moved.</p>
      <router-link to="/">Go back to home</router-link>
    </div>
  </template>

  <script>
  export default {
    name: 'NotFoundView'
  }
  </script>

  <style scoped>
  .not-found {
    text-align: center;
    padding: 2rem;
  }

  h1 {
    font-size: 3rem;
    margin-bottom: 1rem;
  }

  p {
    font-size: 1.2rem;
    margin-bottom: 2rem;
  }

  a {
    color: var(--primary-color);
    text-decoration: none;
    font-weight: bold;
  }

  a:hover {
    text-decoration: underline;
  }
  </style>