<template>
  <div id="app" :class="{ 'dark-mode': isDarkMode }">
    <header>
      <router-link to="/" class="logo">
        <img src="@/assets/abovedata_logo.png" alt="AboveData Logo">
      </router-link>
      <nav>
        <router-link to="/">Home</router-link>
        <router-link to="/about">About</router-link>
        <router-link to="/services">Services</router-link>
        <router-link to="/contact">Contact</router-link>
      </nav>
    </header>
    <router-view></router-view>
    <footer>
      <p>&copy; 2024 Abovedata. All rights reserved.</p>
    </footer>
  </div>
</template>

<script>
export default {
  name: 'App',
  data() {
    return {
      isDarkMode: false
    }
  },
  methods: {
    toggleDarkMode() {
      this.isDarkMode = !this.isDarkMode;
    }
  }
}
</script>

<style>
:root {
  --background-color: hsla(0,0%,0%,1);
  --text-color: rgb(199, 193, 182);
  --primary-color: hsla(72,100%,50%,1);
  --secondary-color: hsla(204,18.8%,26.08%,1);
  --border-color: hsla(0,0%,100%,.25);
  --input-background: hsla(204,18.8%,26.08%,1);
  --label-color: hsla(0,0%,100%,1);
}

body {
  background-color: var(--background-color);
  color: var(--text-color);
  font-family: 'League Spartan', sans-serif;
  font-size: 18.0667px;
  font-weight: 300;
  letter-spacing: 0.361333px;
  line-height: 18.0667px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#app {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

header {
  background-color: var(--background-color);
  padding: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.logo {
  display: flex;
  align-items: center;
  text-decoration: none;
}

.logo img {
  height: auto;
  width: 200px;
}

nav {
  display: flex;
  gap: 1rem;
}

nav a {
  color: var(--text-color);
  text-decoration: none;
  font-size: 1.1rem;
  font-weight: 300;
  letter-spacing: 0.02em;
  line-height: 1.8em;
  transition: color 0.3s;
}

nav a:hover {
  color: var(--primary-color);
}

footer {
  background-color: var(--background-color);
  color: var(--text-color);
  padding: 20px;
  margin-top: auto;
  text-align: center;
  font-size: 1rem;
  letter-spacing: 0.02em;
  line-height: 1.8em;
}

@media (max-width: 768px) {
  header {
    flex-direction: column;
    align-items: flex-start;
  }

  nav {
    margin-top: 1rem;
    flex-wrap: wrap;
  }
}

/* Global styles */
h1 {
  font-size: 4rem;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 1.4em;
  color: var(--text-color);
}

h2 {
  font-size: 2.8rem;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 1.4em;
  color: var(--text-color);
}

p {
  font-size: 1.1rem;
  font-weight: 300;
  letter-spacing: 0.02em;
  line-height: 1.8em;
  color: var(--text-color);
}

button {
  background-color: var(--primary-color);
  color: var(--background-color);
  font-family: 'League Spartan', sans-serif;
  font-size: 1.1rem;
  font-weight: 500;
  letter-spacing: 0.05em;
  line-height: 1.2em;
  padding: 1.2em 2.004em;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
}

button:hover {
  background-color: var(--text-color);
  color: var(--background-color);
}
</style>
