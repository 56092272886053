<template>
  <div class="home">
    <h1>Abovedata Financial</h1>
    <p class="tagline">Unlocking Hidden Value in Alternative Data</p>
    <div class="content">
      <div class="section">
        <h2>AI-Powered Analysis</h2>
        <p>Transforming industrial intelligence with cutting-edge AI and machine learning</p>
      </div>
      <div class="section">
        <h2>Exclusive Data</h2>
        <p>Access to proprietary datasets for unparalleled insights into industrial sectors</p>
      </div>
      <div class="section">
        <h2>Actionable Intelligence</h2>
        <p>Turning complex data into clear, actionable intelligence</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HomeView'
}
</script>

<style scoped>
.home {
  padding: 2rem;
  max-width: 1400px;
  margin: 0 auto;
}

h1 {
  font-size: 3rem;
  margin-bottom: 0.5rem;
}

.tagline {
  font-size: 1.5rem;
  color: var(--primary-color);
  margin-bottom: 2rem;
}

.content {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2rem;
}

@media (max-width: 1200px) {
  .content {
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  }
}

.section {
  background-color: var(--secondary-color);
  padding: 1.5rem;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(255, 255, 255, 0.1);
  transition: transform 0.3s, box-shadow 0.3s;
  border: 1px solid var(--border-color);
}

.section:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 8px rgba(255, 255, 255, 0.15);
}

.section h2 {
  font-size: 1.5rem;
  margin-bottom: 1rem;
  color: var(--primary-color);
}

.section p {
  font-size: 1rem;
  line-height: 1.5;
}

@media (max-width: 768px) {
  .home {
    padding: 1rem;
  }

  h1 {
    font-size: 2.5rem;
  }

  .tagline {
    font-size: 1.2rem;
  }

  .content {
    grid-template-columns: 1fr;
  }
}
</style>