<template>
  <div class="contact">
    <h1>Contact Us</h1>
    <p class="tagline">Get in Touch with Our Team</p>
    <div class="content">
      <div class="section contact-info">
        <h2>Contact Information</h2>
        <p><strong>Email:</strong> info@abovedata.com</p>
        <p><strong>Phone:</strong> +1 (555) 123-4567</p>
        <p><strong>Address:</strong> 123 Financial Street, New York, NY 10001</p>
      </div>
      <div class="section contact-form">
        <h2>Send Us a Message</h2>
        <form @submit.prevent="sendEmail">
          <div class="form-group">
            <label for="name">Name:</label>
            <input type="text" id="name" v-model="formData.name" required placeholder="John Doe">
          </div>
          <div class="form-group">
            <label for="organization">Organization:</label>
            <input type="text" id="organization" v-model="formData.organization" required placeholder="Company Inc.">
          </div>
          <div class="form-group">
            <label for="email">Email:</label>
            <input type="email" id="email" v-model="formData.email" required placeholder="john@example.com">
          </div>
          <div class="form-group">
            <label for="phone">Cell Phone:</label>
            <input type="tel" id="phone" v-model="formData.phone" required placeholder="+1 (555) 123-4567">
          </div>
          <div class="form-group">
            <label for="message">Message:</label>
            <textarea id="message" v-model="formData.message" required placeholder="Your message here..."></textarea>
          </div>
          <button type="submit">Send Message</button>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import emailjs from 'emailjs-com';

export default {
  name: 'ContactView',
  data() {
    return {
      formData: {
        name: '',
        organization: '',
        email: '',
        phone: '',
        message: ''
      },
      emailjsConfigured: false // Add this line to check if EmailJS is configured
    }
  },
  mounted() {
    // Check if EmailJS is configured
    this.emailjsConfigured =
      process.env.VUE_APP_EMAILJS_SERVICE_ID &&
      process.env.VUE_APP_EMAILJS_TEMPLATE_ID &&
      process.env.VUE_APP_EMAILJS_USER_ID;
  },
  methods: {
    sendEmail() {
      if (this.emailjsConfigured) {
        emailjs.send(
          process.env.VUE_APP_EMAILJS_SERVICE_ID,
          process.env.VUE_APP_EMAILJS_TEMPLATE_ID,
          this.formData,
          process.env.VUE_APP_EMAILJS_USER_ID
        )
        .then((result) => {
          console.log('Email sent successfully:', result.text);
          alert('Your message has been sent successfully!');
          this.resetForm();
        }, (error) => {
          console.log('Failed to send email:', error.text);
          this.saveMessageLocally();
        });
      } else {
        this.saveMessageLocally();
      }
    },
    saveMessageLocally() {
      const timestamp = new Date().toISOString();
      const messageData = {
        ...this.formData,
        timestamp
      };

      let storedMessages = JSON.parse(localStorage.getItem('contactMessages') || '[]');
      storedMessages.push(messageData);
      localStorage.setItem('contactMessages', JSON.stringify(storedMessages));

      console.log('Message saved locally');
      alert('Your message has been saved locally and will be processed later.');
      this.resetForm();
    },
    resetForm() {
      this.formData = {
        name: '',
        organization: '',
        email: '',
        phone: '',
        message: ''
      };
    }
  }
}
</script>

<style scoped>
.contact {
  padding: 2rem;
  max-width: 1400px;
  margin: 0 auto;
  box-sizing: border-box;
}

h1 {
  font-size: 3rem;
  margin-bottom: 0.5rem;
}

.tagline {
  font-size: 1.5rem;
  color: var(--primary-color);
  margin-bottom: 2rem;
}

.content {
  display: flex;
  gap: 2rem;
}

.section {
  background-color: var(--secondary-color);
  padding: 1.5rem;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(255, 255, 255, 0.1);
  transition: transform 0.3s, box-shadow 0.3s;
  border: 1px solid var(--border-color);
}

.contact-info, .contact-form {
  flex: 1;
}

.section h2 {
  font-size: 1.5rem;
  margin-bottom: 1rem;
  color: var(--primary-color);
}

.form-group {
  margin-bottom: 1.5rem;
}

label {
  display: block;
  margin-bottom: 0.5rem;
  font-weight: bold;
  color: var(--text-color);
}

input, textarea {
  width: 100%;
  padding: 0.75rem;
  border: 2px solid var(--border-color);
  border-radius: 4px;
  outline: none;
  transition: border-color 0.3s, box-shadow 0.3s;
  background-color: var(--input-background);
  color: var(--text-color);
  font-size: 1rem;
  box-sizing: border-box;
}

input::placeholder, textarea::placeholder {
  color: rgba(199, 193, 182, 0.5);
}

input:focus, textarea:focus {
  border-color: var(--primary-color);
  box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.2);
}

button {
  background-color: var(--primary-color);
  color: var(--background-color);
  border: none;
  padding: 0.75rem 1.5rem;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
  font-weight: bold;
  font-size: 1rem;
}

button:hover {
  background-color: var(--text-color);
  color: var(--background-color);
}

@media (max-width: 768px) {
  .contact {
    padding: 1rem;
    width: 100%;
  }

  h1 {
    font-size: 2.5rem;
  }

  .tagline {
    font-size: 1.2rem;
  }

  .content {
    flex-direction: column;
  }

  .section {
    margin-bottom: 1.5rem;
    padding: 1rem;
  }

  input, textarea, button {
    font-size: 16px; /* Prevents zoom on mobile */
    width: 100%;
    max-width: 100%;
    box-sizing: border-box;
  }
}
</style>