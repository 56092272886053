<template>
    <div class="services">
      <h1>Our Services</h1>
      <p class="tagline">Comprehensive Alternative Financial Data Solutions</p>
      <div class="content">
        <div class="section">
          <h2>Data Collection</h2>
          <p>We gather alternative data from various sources, including satellite imagery, social media, and web scraping.</p>
        </div>
        <div class="section">
          <h2>Data Analysis</h2>
          <p>Our advanced algorithms process and analyze large datasets to extract valuable insights.</p>
        </div>
        <div class="section">
          <h2>Custom Reports</h2>
          <p>We provide tailored reports and visualizations to meet your specific needs and requirements.</p>
        </div>
        <div class="section">
          <h2>API Access</h2>
          <p>Integrate our data directly into your systems with our robust and secure API.</p>
        </div>
      </div>
    </div>
  </template>

  <script>
  export default {
    name: 'ServicesView'
  }
  </script>

  <style scoped>
  .services {
    padding: 2rem;
    max-width: 1400px;
    margin: 0 auto;
  }

  h1 {
    font-size: 3rem;
    margin-bottom: 0.5rem;
  }

  .tagline {
    font-size: 1.5rem;
    color: var(--primary-color);
    margin-bottom: 2rem;
  }

  .content {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 2rem;
  }

  .section {
    background-color: var(--secondary-color);
    padding: 1.5rem;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(255, 255, 255, 0.1);
    transition: transform 0.3s, box-shadow 0.3s;
    border: 1px solid var(--border-color);
  }

  .section:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 8px rgba(255, 255, 255, 0.15);
  }

  .section h2 {
    font-size: 1.5rem;
    margin-bottom: 1rem;
    color: var(--primary-color);
  }

  .section p {
    font-size: 1rem;
    line-height: 1.5;
  }

  @media (max-width: 768px) {
    .services {
      padding: 1rem;
    }

    h1 {
      font-size: 2.5rem;
    }

    .tagline {
      font-size: 1.2rem;
    }
  }
  </style>