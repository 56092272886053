<template>
    <div class="about">
      <h1>About Abovedata</h1>
      <p class="tagline">Bridging the Gap Between Raw Data and Industrial Intelligence</p>
      <div class="content">
        <div class="section">
          <h2>Our Mission</h2>
          <p>At AboveData, we are dedicated to transforming complex industrial data into actionable insights.</p>
          <p>We empower hedge funds and financial institutions to make informed decisions in an ever-evolving market landscape.</p>
        </div>
        <div class="section">
          <h2>Our Expertise</h2>
          <p>Our team consists of seasoned data scientists and industry experts.</p>
          <p>We specialize in collecting, analyzing, and delivering high-quality alternative data sets.</p>
          <p>Our unique perspectives on industrial sectors provide a competitive edge to our clients.</p>
        </div>
        <div class="section">
          <h2>Our Commitment</h2>
          <p>We maintain the highest standards of data quality, integrity, and client satisfaction.</p>
          <p>Our cutting-edge AI and machine learning technologies ensure we stay at the forefront of industrial intelligence.</p>
          <p>We are committed to delivering reliable, innovative solutions that drive your success.</p>
        </div>
      </div>
      <h2 class="team-header">Our Team</h2>
      <div class="team-content">
        <div class="team-member">
          <div class="team-member-image">
            <!-- Placeholder for the image -->
            <div class="image-placeholder"></div>
          </div>
          <div class="team-member-info">
            <h3>Ben Webb</h3>
            <p>CEO & Founder</p>
          </div>
        </div>
        <div class="team-member">
          <div class="team-member-image">
            <!-- Placeholder for the image -->
            <div class="image-placeholder"></div>
          </div>
          <div class="team-member-info">
            <h3>Harper Cullen</h3>
            <p>Head of Business Development</p>
          </div>
        </div>
        <!-- Add more team members as needed -->
      </div>
    </div>
  </template>

  <script>
  export default {
    name: 'AboutView'
  }
  </script>

  <style scoped>
  .about {
    padding: 2rem;
    max-width: 1200px;
    margin: 0 auto;
  }

  h1 {
    font-size: 3rem;
    margin-bottom: 0.5rem;
  }

  .tagline {
    font-size: 1.5rem;
    color: var(--primary-color);
    margin-bottom: 2rem;
  }

  .content {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2rem;
  }

  @media (max-width: 1200px) {
    .content {
      grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    }
  }

  .section {
    background-color: var(--secondary-color);
    padding: 1.5rem;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(255, 255, 255, 0.1);
    transition: transform 0.3s, box-shadow 0.3s;
    border: 1px solid var(--border-color);
  }

  .section:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 8px rgba(255, 255, 255, 0.15);
  }

  .section h2 {
    font-size: 1.5rem;
    margin-bottom: 1rem;
    color: var(--primary-color);
  }

  .section p {
    font-size: 1rem;
    line-height: 1.5;
  }

  @media (max-width: 768px) {
    .about {
      padding: 1rem;
    }

    h1 {
      font-size: 2.5rem;
    }

    .tagline {
      font-size: 1.2rem;
    }

    .content {
      grid-template-columns: 1fr;
    }
  }

  .team-header {
    text-align: center;
    margin-top: 3rem;
    margin-bottom: 2rem;
    color: var(--primary-color);
  }

  .team-content {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 1.5rem;
    padding: 0 1rem;
  }

  .team-member {
    background-color: var(--secondary-color);
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 4px 6px rgba(255, 255, 255, 0.1);
    transition: transform 0.3s, box-shadow 0.3s;
    border: 1px solid var(--border-color);
  }

  .team-member:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 8px rgba(255, 255, 255, 0.15);
  }

  .team-member-image {
    width: 100%;
    padding-top: 100%; /* 1:1 Aspect Ratio */
    position: relative;
    overflow: hidden;
  }

  .image-placeholder {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: var(--background-color);
  }

  .team-member-info {
    padding: 1rem;
    text-align: center;
  }

  .team-member-info h3 {
    margin: 0 0 0.5rem 0;
    font-size: 1.2rem;
    color: var(--text-color);
  }

  .team-member-info p {
    margin: 0;
    font-size: 0.9rem;
    color: var(--text-color-secondary);
  }

  @media (max-width: 768px) {
    .team-content {
      grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
    }
  }

  .team-member img {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    object-fit: cover;
    margin-bottom: 1rem;
  }

  .team-member h3 {
    margin-bottom: 0.5rem;
  }

  .team-member p {
    color: var(--text-color-secondary);
  }
  </style>